import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { LoginRequest, RegistrarRequest, RegistrarResponse } from "../models/auth.model";
import { NgHttpService } from "./httpservice";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /**
   *
   */
  constructor(private http: HttpClient,
    private httpService: NgHttpService,
    private _router: Router) {

  }

  public Register(registrar: RegistrarRequest): Observable<RegistrarResponse> {
    return this.httpService.post(`usuario`, registrar);
  }

  public Login(registrar: LoginRequest): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(
      `${environment.apiAuthUrl}/api/auth/login`, registrar,
      {
        headers
      }
    );
  }

  confirmar(Token: string): Observable<any> {
    return this.httpService.get(`Usuario/Ativar`, { Token });
  }

  getUserLogged() {
    return JSON.parse(localStorage.getItem('userLogged'));
  }

  usuarioLogado(params: any = {}): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/Usuario/UsuarioLogado`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
  }

  getMenu(params: any = {}): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/Clientes/RetornaAcesso`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })
  }

  logout() {
    localStorage.clear();
    this._router.navigate(['auth/login']);
  }

  listarPorPerfilSub(perfilId, subFuncionalidadeId): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/PerfilControleAcesso/ListarPorPerfilSub?perfilId=${perfilId}&codigoSubFuncionalidade=${subFuncionalidadeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`
        }
      })



  }

}
